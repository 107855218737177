import axios from 'axios'
import {
  AxiosRefreshClientHandlers,
  AxiosAccessClientHandlers,
} from './clients.functions'
import {
  axiosClientConfig,
  axiosClientRefreshConfig,
} from './clients.constants'

const axiosClient = axios.create(axiosClientConfig)

const axiosClientRefresh = axios.create(axiosClientRefreshConfig)

axiosClientRefresh.interceptors.request.use(
  AxiosRefreshClientHandlers.handleRequest,
)

axiosClientRefresh.interceptors.response.use(
  undefined,
  AxiosRefreshClientHandlers.handleResponse,
)

axiosClient.interceptors.request.use(AxiosAccessClientHandlers.handleRequest)

axiosClient.interceptors.response.use(undefined, (err) =>
  AxiosAccessClientHandlers.handleResponse(
    err,
    axiosClient,
    axiosClientRefresh,
  ),
)

export { axiosClient, axiosClientRefresh }
