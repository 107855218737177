export const axiosClientConfig = {
  baseURL: window._env_?.REACT_APP_SERVER_URL ?? '',
  token: sessionStorage.getItem('token') ?? '',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token') ?? ''}`,
  },
}

export const axiosClientRefreshConfig = {
  baseURL: window._env_.REACT_APP_SERVER_URL,
  token: sessionStorage.getItem('refreshToken') ?? '',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('refreshToken') ?? ''}`,
  },
}
